import React from 'react'
import { message, Form, Button } from 'antd'
import { useMutation } from '@apollo/client'
import { FormattedMessage } from 'react-intl'
import startUploadMutation from '~/graphql/mutations/startUpload.gql'
import eventQuery from '~/graphql/queries/event.gql'
import moment from 'moment'

// const formItemLayout = {
//   labelCol: {
//     xs: { span: 24 },
//     sm: { span: 8 },
//   },
//   wrapperCol: {
//     xs: { span: 24 },
//     sm: { span: 8 },
//   },
// }
// const tailFormItemLayout = {
//   wrapperCol: {
//     xs: {
//       span: 24,
//       offset: 0,
//     },
//     sm: {
//       span: 8,
//       offset: 10,
//     },
//   },
// }

const PhotoUploadRequest = ({ user, event, approvalRequired, registrable, agreementAccept, showModal }) => {
  const [form] = Form.useForm()
  const [startUpload] = useMutation(startUploadMutation)
  const handleSubmit = async (values) => {
    // return console.log({pp:values})
    if (event.registrationMethod !== 'free' && (moment(event.registrationEndsAt) < moment() || moment(event.registrationStartsAt) > moment())) {
      message.error('Registration failed')
    } else {
      const { record } = values
      try {
        await startUpload({
          variables: { ...record, eventId: event._id },
          refetchQueries: () => [
            {
              query: eventQuery,
              variables: { _id: event._id },
            },
          ],
          awaitRefetchQueries: true,
        })
      } catch (err) {
        console.error(err)
      }
    }
  }
  return (
    <Form form={form} onFinish={handleSubmit}>
      
      <Form.Item>
        {event.photographerNumber && event.photographerNumber <= event.approvedUploadCount ? (
          <Button
            style={{width: '100%', height: 50, fontSize: 18, background:'#BD188B', color:'white'}}
            // type='primary'
            onClick={showModal}
            disabled={
              !registrable ||
              !agreementAccept ||
              moment(event.registrationEndsAt) < moment() ||
              moment(event.registrationStartsAt) > moment() ||
              event.registrationMethod === 'manual'
            }
          >
            {approvalRequired ? (
              <FormattedMessage id='app.uploadRequest' defaultMessage='Register for upload request' />
            ) : (
              <FormattedMessage id='app.startUpload' defaultMessage='Start uploading' />
            )}
          </Button>
        ) : (
          <Button
            style={{width: '100%', height: 50, fontSize: 18, background:'#BD188B', color:'white'}}
            // type='primary'
            htmlType='submit'
            disabled={
              !registrable ||
              !agreementAccept ||
              moment(event.registrationEndsAt) < moment() ||
              moment(event.registrationStartsAt) > moment() ||
              event.registrationMethod === 'manual'
            }
          >
            {approvalRequired ? (
              <FormattedMessage id='app.uploadRequest' defaultMessage='Register for upload request' />
            ) : (
              <FormattedMessage id='app.uploadRequest' defaultMessage='Start uploading' />
            )}
          </Button>
        )}
        {/* <p>ตอนนี้ระบบอัปโหลดภาพขัดข้องชั่วคราว ทีมงานกำลังเร่งแก้ไข</p> */}
      </Form.Item>
      {/* {event.photoDownloadMode !== 'free' && (
        <Form.Item
          // {...formItemLayout}
          name={['record', 'photoSizes']}
          label={<FormattedMessage id='app.uploadPhotoSizes' defaultMessage='Photo sizes to sell' />}
          initialValue={event.photoSizes.map((size) => size._id)}
        >
          <Checkbox.Group options={event.photoSizes.map((size) => ({ label: size._id, value: size._id }))} />
        </Form.Item>
      )} */}
      {/* {approvalRequired && (
        <Form.Item {...formItemLayout} label={<FormattedMessage id='app.username' defaultMessage='Username' />}>
          <span>{user.username}</span>
        </Form.Item>
      )} */}
      {/* {!agreementAccept && <FormattedMessage id="app.pleaseAccept" defaultMessage="Please accept the agreement" />} */}
      {/* {!registrable && <FormattedMessage id='app.cannotRegister' defaultMessage='You cannot register for this event' />}
      {(moment(event.registrationEndsAt) < moment() || moment(event.registrationStartsAt) > moment()) && (
        <FormattedMessage id='app.notRegistrationTime' defaultMessage='Registration for this event is not available' />
      )} */}
    </Form>
  )
}

export default PhotoUploadRequest
