import { useQuery } from '@apollo/client'
import { Select, Spin } from 'antd'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import photoPackagesQuery from '../graphql/queries/photoPackages.gql'

const PhotoPackageMultipleSelect = ({ onChange, value }) => {
  const intl = useIntl()
  const [search, setSearch] = useState()
  const { loading, data } = useQuery(photoPackagesQuery, {
    variables: {
      isDefault: true,
      nameSearch: search,
    },
  })
  const handleOnChange = (value) => {
    onChange(value)
  }
  const photoPackages = data?.photoPackages ?? []
  if (loading) {
    return <Spin style={{ display: 'block' }} />
  }
  return (
    <Select
      allowClear
      filterOption={false}
      loading={loading}
      maxTagCount={6}
      mode='multiple'
      onBlur={() => setSearch()}
      onChange={handleOnChange}
      onSearch={(v) => setSearch(v)}
      placeholder={intl.formatMessage({ id: 'app.select', defaultMessage: 'Please Select...' })}
      showSearch
      value={value}
    >
      {photoPackages.map((p) => (
        <Select.Option key={p._id} value={p._id}>
          {p.name}
        </Select.Option>
      ))}
    </Select>
  )
}

export default PhotoPackageMultipleSelect
