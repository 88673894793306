import { useQuery } from '@apollo/client'
import { Select } from 'antd'
import React, { useState } from 'react'
import adminUserNamesQuery from '../graphql/queries/adminUserNames.gql'

const AdminUserList = ({ onChange, value, role, style, user }) => {
  const [search, setSearch] = useState('')
  const { error, loading, data } = useQuery(adminUserNamesQuery, {
    variables: {
      role,
      search,
    },
  })
  const {
    error: selectedError,
    loading: selectedLoading,
    data: selectedData,
  } = useQuery(adminUserNamesQuery, {
    skip: !value || !value.length,
    variables: {
      _operators: { _id: { in: Array.isArray(value) ? value : [value] } },
    },
  })
  if (error || selectedError) {
    return 'Error'
  }
  const users = data?.adminUserList?.items ?? []
  const userCount = data?.adminUserList?.count
  const [selectedUser] = selectedData?.adminUserList?.items ?? []
  const adminUser = users.find((user) => user._id === value)
  const { displayName } = adminUser ?? {}
  const { formalName } = adminUser?.[`${role}`] ?? {}
  if (['broker', 'organizer', 'salesperson'].includes(user.role)) {
    return <div style={{ fontSize: '1.2rem' }}>{displayName}</div>
  }
  return (
    <>
      <Select
        showSearch
        loading={loading || selectedLoading}
        filterOption={false}
        onSearch={(value) => (value ? setSearch(value) : setSearch())}
        onChange={onChange}
        onBlur={() => setSearch()}
        onSelect={() => setSearch()}
        value={value ?? 'all'}
        style={{ width: 360, ...style }}
      >
        {!search && (
          <Select.Option key={'all'} value={'all'}>
            All {role}s
          </Select.Option>
        )}
        {!search && selectedUser && (
          <Select.Option key={`_${selectedUser._id}`} value={selectedUser._id}>
            {selectedUser.displayName}
            {role === 'photographer' && <span style={{ color: 'grey' }}> @{selectedUser.username}</span>}
          </Select.Option>
        )}
        {users.map((user) => (
          <Select.Option key={user._id} value={user._id}>
            {user.displayName}
          </Select.Option>
        ))}
      </Select>
      <div style={{ margin: '.5em' }}>
        {selectedUser ? (
          <>{formalName}</>
        ) : ['admin', 'operation_admin', 'superadmin'].includes(user.role) ? (
          <>{userCount} {role}s</>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default AdminUserList
