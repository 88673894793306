import styled from 'styled-components'

export const MainMenu = styled.div`
@media only screen and (min-width: 750px) {
.css-dip3t8 {
  position: relative;
  // position: absolute;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-box-align: center;
  background: #000000;
  // background: linear-gradient(180deg,#bd188b 0%,#7d1c7d 35%,#411a6f 100%);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  // border-radius: 8px;
  margin: auto
}
.css-1wvake5.ps-collapsed {
  width: 80px;
  min-width: 80px;
  z-index: 10;
}

.css-16jesut >.ps-menu-button {
  // display: -webkit-box;
  // display: -webkit-flex;
  // display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  box-sizing: border-box;
  cursor: pointer;
  padding-right: 20px;
  padding-left: 20px;
}

.css-16jesut >.ps-menu-button:hover {
  background: #EAEAEA;
  opacity: 0.6;
  // border-radius: 8px;
  width: 100%;
  // height: 60px;
}

.css-1t8x7v1 >.ps-menu-button:hover {
  background: #EAEAEA;
  opacity: 0.6;
  // border-radius: 8px;
  width: 100%;
  // height: 60px;
}

.subMenu {
  -webkit-box-align: center;
  align-items: center;
  // height: 100px;
  text-decoration: none;
  box-sizing: border-box;
  cursor: pointer;
}
.menu-icon{
  line-height: normal;
  // margin: 8px;
  color: #fff;
  font-size: 27px;
}
.footer {
  position: fixed;
  bottom: 0;
  margin-left: 20px;
  margin-bottom: 10px;
}

.menu-title {
  display: block;
  font-size: 10px;
  /* color: rgba(0, 0, 0, 0.34); */
  color: white;
  margin: -10px;
  # font-family: 'Roboto';
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  // position: relative;
  bottom: 10px;
  line-height: normal;
  // margin: 10px;
  // padding: 5px;
  // margin-bottom: 15px;
}
.menu-item {
  padding-left: 13px;
  font-size: 14px;
  color: #000;  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
  .mobile {
    // border-right: 10px solid #EAEAEA;
    // padding: 10px solid #EAEAEA;
    position: fixed;
    height: 100%;
    .logo {
      // position: relative;
      // float: left; 
      padding-left: 9px;
      padding-top: 23px;
      margin-bottom: 10px;
      img {
        // line-height: normal;
        width: 58px;
        height: auto;
      }
    }
  }
}
  @media only screen and (max-width: 750px) {
    .logo {
      // float: left; 
      padding-left: 15px;
      padding-top: 23px;
      margin-bottom: 10px;
      img {
        width: 58px;
        height: auto;
      }
    }

    // .css-1wvake5.ps-broken.ps-toggled {
    //     left: 0;
    // }

    // .css-1wvake5.ps-broken {
    //     position: fixed;
    //     height: 100%;
    //     top: 0px;
    //     z-index: 100;
    //     left: -250px;
    // }

    .css-1tqrhto >.ps-menu-button {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      height: 50px;
      -webkit-text-decoration: none;
      text-decoration: none;
      color: inherit;
      box-sizing: border-box;
      cursor: pointer;
      padding-right: 20px;
      padding-left: 40px;
      background: #2B2827;
  
  }

  .css-16jesut >.ps-menu-button:hover {
    opacity: 0.6;
    background-color: #2B2827; 
  }

  .css-1tqrhto >.ps-menu-button:hover {
    opacity: 0.6;
    background-color: #2B2827; 
  }

  .css-1t8x7v1 >.ps-menu-button:hover {
    opacity: 0.6;
    background-color: #2B2827; 
  }
  
    .css-dip3t8 {
      position: relative;
      // position: absolute;
      height: 100%;
      color: #fff;
      overflow-y: auto;
      overflow-x: hidden;
      -webkit-box-align: center;
      background: #2F2F2F;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      // border-radius: 8px;
      margin: auto
    }

    .menu-title {
      display: block;
      font-size: 10px;
      // color: rgba(0, 0, 0, 0.34); 
      color: white;
      margin: -10px;
      # font-family: 'Roboto';
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      // position: relative;
      bottom: 10px;
      line-height: normal;
      // margin: 10px;
      // padding: 5px;
      // margin-bottom: 15px;
    }
    .menu-item {
      padding-left: 13px;
      font-size: 14px;
      color: #fff;  
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    }

    .subMenu {
      -webkit-box-align: center;
      align-items: center;
      // height: 100px;
      text-decoration: none;
      box-sizing: border-box;
      cursor: pointer;
      color: #fff;
    }
    .menu-icon{
      margin: 10px;
      color: #fff;
      font-size: 27px;
    }
    .footer {
      position: fixed;
      bottom: 0;
      // align-items: center;
      margin-left: 65px;
      margin-bottom: 10px;
    }

    span.menuTriggerIcon {
      position: absolute;
      left: 20px;
      top: 20px;
      z-index: 1;
    }
    .ant-menu {
      color: rgba(255,255,255,0.7);
    }
    .ant-menu.ant-menu-sub {
      background: unset;
    }
    .pro-sidebar > .pro-sidebar-inner {
      background: #2F2F2F;
    }
    .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
      padding: 0;
    }
    .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
      border-right: unset;
    }
    svg.menuicon {
      position: relative;
      top: 5px;
    }
    span.menutitle {
      margin-left: 16px;
    }
    li.ant-menu-item a {
      color: rgba(255, 255, 255, 0.7);
      font-family: Roboto;
      font-size: 16px;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background: rgba(255, 255, 255, 0.08);
    }
  
    .ant-menu-vertical .ant-menu-item::after,
    .ant-menu-vertical-left .ant-menu-item::after,
    .ant-menu-vertical-right .ant-menu-item::after,
    .ant-menu-inline .ant-menu-item::after {
      border-right: 3px solid #FFFFFF;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a{
      color: #FFFFFF;
    }
    ul.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
      background: #2F2F2F;
    }
    li.ant-menu-item.menulogoitem {
      height: auto;
    } 
    .menulogout {
      color: rgba(255, 255, 255, 0.7);
      font-size: 16px;
      font-family: Roboto;
      padding: 10px;
      /* position: absolute;
      bottom: 21px; */
      /* bottom: calc(25% + 21px); */
      /* left: 30px; */
      span.menu-title {
        margin-left: 16px;
      }
    }
    }
  }
  }
`