import React, { useState } from 'react'
import path from 'path'
import url from 'url'
import { Upload, Button } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import cloudUploadMutation from '~/graphql/mutations/cloudUpload.gql'
import FileIcon from '~/assets/images/file.png'
// import loadImage from 'blueimp-load-image'
import axios from 'axios'
import _get from 'lodash/get'
import { FormattedMessage } from 'react-intl'

const { Dragger } = Upload

const CloudUploader = React.forwardRef(({ onChange, value, provider = 'gs', path: fullPath, customFileName, disabled, accept }, ref) => {
  const [loading, setLoading] = useState(false)
  const [upload] = useMutation(cloudUploadMutation)

  const customRequest = async ({ file }) => {
    setLoading(true)
    const fileName = customFileName && customFileName(file.name)
    const response = await upload({
      variables: {
        provider,
        path: fullPath,
        mimeType: file.type,
        fileName
      }
    })
    const { uploadUrl, downloadUrl } = _get(response, 'data.upload', {})
    if (uploadUrl) {
      await axios.put(uploadUrl, file, {
        headers: {
          'Content-Type': file.type,
          ...(provider === 'gs' && {
            'x-goog-acl': 'public-read'
          })
        }
      })

      setLoading(false)
      onChange(downloadUrl)
    }
  }
  const onRemove = () => {
    onChange()
  }
  const fileList = value ? [{
    uid: value,
    name: path.basename(url.parse(value).pathname),
    status: 'done',
    url: value
  }] : []

  // const { value } = this.props
  return (
    <Dragger style={{ borderRadius: 8 }} ref={ref} customRequest={customRequest} fileList={fileList} onRemove={onRemove} accept={accept} listType="picture">
      {loading ? (
        <Button block disabled style={{ height: 90}} loading={loading}>
          <div style={{textAlign: 'center'}}>Uploading...</div>
        </Button>
      ) : value ? (
        <Button icon={<UploadOutlined />} disabled={disabled}>
          <FormattedMessage id='app.reUpload' defaultMessage='Re-Upload' />
        </Button>
      ) : (
          // <Button style={{ width: '100%', height: 90}} >
            <div style={{textAlign: 'center'}} loading={loading} disabled={disabled}>
              <img src={FileIcon} alt='PDFIcon' style={{width: '50px', margin: 5}}/>
              <div>Upload a file or drag and drop</div>
              <div>Supported file formate : PDF, JPG, PNG </div>
            </div>
          // </Button>
          
      )}
    </Dragger>
  )
})

export default CloudUploader
