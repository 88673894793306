import { useQuery } from '@apollo/client'
import { Select, Spin } from 'antd'
import { inject } from 'mobx-react'
import React, { useState } from 'react'
import { FormattedNumber, FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import AdminUserSelect from '../components/AdminUserSelect'
import TransferRequestForm from '../components/TransferRequestForm'
import Toolbar from '../components/Toolbar'
import config from '../config'
import adminUserFinancialQuery from '../graphql/queries/adminUserFinancial.gql'

const MINIMUM_AMOUNT = config.minimumTransferRequestAmount

const TransferRequestCreate = ({ user }) => {
  const { _id, role } = user ?? {}
  const authorized = ['superadmin', 'admin'].includes(role)
  const [selectedRole, setSelectedRole] = useState(['broker', 'organizer', 'photographer'].includes(role) ? role : undefined)
  // TODO: better ACL
  // TODO: handle non-authorized
  const [userId, setUserId] = useState(['broker', 'organizer', 'photographer'].includes(role) ? _id : undefined)
  const { error, loading, data } = useQuery(adminUserFinancialQuery, {
    skip: !userId,
    variables: { _id: userId, role: 'photographer' },
    fetchPolicy: 'network-only',
  })
  const { adminUser } = data ?? {}
  const handleRoleChange = (role) => {
    setSelectedRole(role)
    setUserId()
  }
  const handleAdminUserSelect = (userId) => {
    setUserId(userId)
  }
  if (error) {
    return 'Error'
  }
  return (
    <div>
      <Toolbar
        left={
          <div>
            {authorized && (
              <Select
                placeholder={<FormattedMessage id='app.select' defaultMessage='Please Select...' />}
                onChange={handleRoleChange}
                value={selectedRole}
                style={{ width: 160 }}
              >
                {['broker', 'organizer', 'photographer'].map((el) => (
                  <Select.Option key={el} value={el}>
                    {el}
                  </Select.Option>
                ))}
              </Select>
            )}
            {selectedRole && authorized && (
              <AdminUserSelect onChange={handleAdminUserSelect} role={selectedRole} style={{ width: 300 }} value={userId} />
            )}
          </div>
        }
      />
      {loading ? (
        <Spin style={{ display: 'block', lineHeight: '100vh' }} />
      ) : !userId && authorized ? (
        <div>
          <FormattedMessage id='app.selectUser' defaultMessage='Please Select User!' />
        </div>
      ) : (config.payoutProvider === 'opn' && !adminUser?.recipientId) || (config.payoutProvider === 'manual' && !adminUser?.bankAccount?.number) ? (
        <div style={{ border: '1px solid gainsboro', padding: '1em', margin: '1em', borderRadius: '6px', textAlign: 'center' }}>
          <FormattedMessage id='app.notice.noBankAccount' defaultMessage='No bank account linked, please link bank account' />
          &nbsp;
          <Link to='/profile/edit'>
            <FormattedMessage id='app.action.editAccount' defaultMessage='Edit Account' />
          </Link>
        </div>
      ) : false && config.payoutProvider === 'opn' && (!adminUser?.recipient?.active || !adminUser?.recipient?.verified) ? (
        <div style={{ border: '1px solid gainsboro', padding: '1em', margin: '1em', borderRadius: '6px', textAlign: 'center' }}>
          <FormattedMessage
            id='app.notice.recipientNotActivated'
            defaultMessage='Your bank account is not yet verified or activated, please wait around 1 hour, if your account is still not verified after 1 hour, please contact admin@thai.run'
            values={{
              contactEmail: config.email,
            }}
          />
        </div>
      ) : adminUser?.incompleteTransfers?.length > 0 ? (
        <div style={{ border: '1px solid gainsboro', padding: '1em', margin: '1em', borderRadius: '6px', textAlign: 'center' }}>
          <FormattedMessage
            id='app.notice.incompleteTransfer'
            defaultMessage='You have an incomplete transfer request (pending approval or pending transfer), please wait wait until the request is reviewed and completed before creating a new withdrawal request.'
          />
        </div>
      ) : adminUser?.balance?.total < MINIMUM_AMOUNT ? (
        <div style={{ border: '1px solid gainsboro', padding: '1em', margin: '1em', borderRadius: '6px', textAlign: 'center' }}>
          <FormattedMessage
            id='app.notice.insufficientBalance'
            defaultMessage='Your balance is {amount}, you must have at least {minimumAmount} in order to create a withdrawal request.'
            values={{
              amount: <FormattedNumber value={adminUser.balance.total} minimumFractionDigits={2} style='currency' currency={config.currency} />, /* eslint-disable-line react/style-prop-object */
              minimumAmount: <FormattedNumber value={MINIMUM_AMOUNT} minimumFractionDigits={2} style='currency' currency={config.currency} />, /* eslint-disable-line react/style-prop-object */
            }}
          />
        </div>
      ) : (
        <TransferRequestForm adminUser={adminUser} role={'photographer'} userId={userId} />
      )}
    </div>
  )
}

export default compose(inject('user'))(TransferRequestCreate)
