import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Modal, message, DatePicker, Form, Upload, InputNumber } from 'antd'
import moment from 'moment-timezone'
import createOrUpdateManualTransferMutation from '~/graphql/mutations/createOrUpdateManualTransfer.gql'
import salespersonEventsQuery from '~/graphql/queries/salespersonEvents.gql'
// import CloudImageUploader from '~/components/CloudImageUploader'
import CloudUploader from '~/components/CloudUploader'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
}

const SalespersonTransferButton = ({ event }) => {
  const [form] = Form.useForm()
  const { getFieldsValue, resetFields } = form
  const [createOrUpdateManualTransfer] = useMutation(createOrUpdateManualTransferMutation)
  const [open, setOpen] = useState(false)
  const handleOk = async () => {
    const values = getFieldsValue(true)
    const {
      record: { completedAt: $completedAt, ...record }
    } = values
    const completedAt = $completedAt.startOf('day')
    try {
      await createOrUpdateManualTransfer({
        variables: {
          record: {
            completedAt,
            eventId: event._id,
            userId: event.salespersonId,
            userRole: 'salesperson',
            ...record
          }
        },
        refetchQueries: [
          {
            query: salespersonEventsQuery,
            ...(event.salespersonId && {
              variables: { salespersonId: event.salespersonId }
            })
          }
        ]
      })
      message.success('success', 4)
    } catch (err) {
      message.error(err)
    } finally {
      resetFields()
      setOpen(false)
    }
  }
  const handleClick = () => setOpen(true)
  const handleRemoveSlip = async () => {
    const values = getFieldsValue(true)
    const { record } = values
    await createOrUpdateManualTransfer({
      variables: {
        record: {
          ...record,
          slipUrl: null
        }
      }
    })
    setOpen(false)
  }
  const { salespersonTransfer } = event
  return (
    <>
      {salespersonTransfer?.slipUrl ? (
        <Upload
          defaultFileList={[{ uid: salespersonTransfer?.slipUrl, url: salespersonTransfer?.slipUrl, name: 'ดาวน์โหลดสลิป' }]}
          showUploadList={{ showRemoveIcon: true, showDownloadIcon: true }}
          onRemove={handleRemoveSlip}
        />
      ) : (
        <Button onClick={handleClick}>แนบหลักฐาน</Button>
      )}
      <Modal
        title={event.title}
        open={open}
        closable
        // onCancel={(e) => console.log(e, e.button, e.target)}
        onOk={handleOk}
        onCancel={() => setOpen(false)}
        maskClosable
      >
        <Form form={form}>
          <Form.Item {...formItemLayout} label='ส่วนแบ่ง Salesperson'>
            {event.totalSalespersonShare?.toFixed(2)}
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name={['record', 'amount']}
            label='ยอดเงินโอน'
            initialValue={salespersonTransfer?.amount ?? Number.parseFloat(event.totalSalespersonShare?.toFixed(2))}
            rules={[{ required: true, message: '' }]}
          >
            <InputNumber min={1} disabled={!!salespersonTransfer?.completedAt} />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name={['record', 'completedAt']}
            label='วันที่โอนเงิน'
            initialValue={salespersonTransfer?.completedAt && moment(salespersonTransfer?.completedAt)}
            rules={[{ required: true, message: '' }]}
          >
            <DatePicker disabledDate={(current) => current.isSameOrBefore(salespersonTransfer?.shareEndsAt)} disabled={!!salespersonTransfer?.completedAt} />
          </Form.Item>
          <Form.Item {...formItemLayout} name={['record', 'slipUrl']} label='แนบสลิป' rules={[{ required: true, message: '' }]}>
            {/* <CloudImageUploader provider='gs' path='documents' accept='.jpg,.jpeg,.png' /> */}
            <CloudUploader provider='gs' path='documents' accept='.pdf,image/*' />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default SalespersonTransferButton
