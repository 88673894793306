import React from "react"
import { useQuery } from "@apollo/client"
import orderQuery from '~/graphql/queries/order.gql'
import { Tag, Tooltip, Typography, Spin, List } from "antd"
import { LinkOutlined } from '@ant-design/icons'
import moment from "moment"
import { useIntl } from "react-intl"
import { inject } from "mobx-react"
import config from "../config"
const OrderDetail = inject('user')(({ user, orderId }) => {
  const intl = useIntl()
  const { data, loading } = useQuery(orderQuery, {
    variables: {
      _id: orderId,
    }
  })
  if (loading) {
    return <Spin style={{ display: 'block' }} />
  }
  const { order } = data
  const photoSizeCountMap = order?.photoItems?.reduce((result, photoItem) => ({
    ...result,
    [photoItem.sizeLabel]: (result[photoItem.sizeLabel] ?? 0) + 1
  }), {}) ?? {}
  const photoSizeCountRecords = Object.entries(photoSizeCountMap).map(([size, count]) => ({
    size, count
  }))
  // console.log({ data })
  return <>
    <div style={{ background: 'white' }}>
      <Typography.Title level={4}>
        {order.url ? <a href={order.url} target="_blank" rel="noopener noreferrer" >{order.code} <LinkOutlined /></a> : order.code}
        {' '}
        {['superadmin', 'admin', 'operation_admin', 'accounting', 'customerService'].includes(user.role)
          ? <Typography.Text strong style={{ color: '#52c41a', float: 'right', marginRight: '1em' }}>
            + {intl.formatNumber(order.shareableAmount, { style: 'currency', currency: config.currency, minimumFractionDigits: 2, currencyDisplay: 'code' })}
            {/* + <FormattedNumber value={order.shareableAmount} style="currency" currency={config.currency} currencySign="code" />eslint-disable-line react/style-prop-object */}
            {/* {record.creditAmount > 0 && '+'}<FormattedNumber value={record.creditAmount} /> */}
          </Typography.Text>
          :<Typography.Text strong style={{ color: '#52c41a', float: 'right', marginRight: '1em' }}>
            + {intl.formatNumber(order.currentUserShare?.amount, { style: 'currency', currency: config.currency, minimumFractionDigits: 2, currencyDisplay: 'code' })}
            {/* + <FormattedNumber value={order.currentUserShare?.amount} style="currency" currency={config.currency} currencySign="code" />eslint-disable-line react/style-prop-object */}
            {/* {record.creditAmount > 0 && '+'}<FormattedNumber value={record.creditAmount} /> */}
          </Typography.Text>}
      </Typography.Title>
      {order.event && <Typography.Paragraph strong>
        {order.event.title}
      </Typography.Paragraph>}
      {order.completedAt && <Typography.Text type="secondary">
        {moment(order.completedAt).format('lll')}
      </Typography.Text>}
    </div>
    <div>
      {photoSizeCountRecords.map(({ size, count }) => <div key={size} style={{ marginTop: '.5em' }}>
        <Tag color="blue">{size}</Tag>✕ {count}
      </div>)}
    </div>
    {['superadmin', 'admin', 'operation_admin', 'accounting'].includes(user.role) && <List
      dataSource={order.shares}
      renderItem={share =>
        share.role
        ? <List.Item
          extra={<Typography.Text strong style={{ color: '#52c41a' }}>
            + {intl.formatNumber(share.amount, { style: 'currency', currency: config.currency, minimumFractionDigits: 2, currencyDisplay: 'code' })}
            {/* + {intl.formatNumber(share.amount, { minimumFractionDigits: 2 })} THB */}
            {/* {record.creditAmount > 0 && '+'}<FormattedNumber value={record.creditAmount} /> */}
          </Typography.Text>}
        >
          <List.Item.Meta
            title={<>{share.user?.displayName} <Tag>{share.role}</Tag></>}
            description={share.user?.username}
          />
        </List.Item>
        : <List.Item
          extra={<Typography.Text strong style={{ color: '#52c41a' }}>
            + {intl.formatNumber(share.amount, { style: 'currency', currency: config.currency, minimumFractionDigits: 2, currencyDisplay: 'code' })}
            {/* + {intl.formatNumber(share.amount, { minimumFractionDigits: 2 })} THB */}
            {/* {record.creditAmount > 0 && '+'}<FormattedNumber value={record.creditAmount} /> */}
          </Typography.Text>}
        >
          <List.Item.Meta
            title={<Tag>platform</Tag>}
            // description={share.user?.username}
          />
        </List.Item>
      }
    />}
    <div className="photos">
      {order?.photoItems?.map(({ photo, sizeLabel }) => (
        <div key={`${photo._id}:${sizeLabel}`} className="photo" >
          <div className="image">
            {photo?.view?.thumbnail.url &&
              <Tooltip 
                title={photo.fileName}
                // content={<img src={photo.view.thumbnail.url}/>}
                // style={{ textAlign: 'center' }}
                // overlayStyle={{ textAlign: 'center' }}
              >
                <img alt={photo.fileName} src={photo.view.thumbnail.url} />
              </Tooltip>
            }
            <Tag color="blue" style={{ position: 'absolute', top: 0, right: 0, margin: '.5em' }}>{sizeLabel}</Tag>
            <Tag style={{ position: 'absolute', bottom: 0, right: 0, margin: '.5em' }}>{photo.fileName}</Tag>
          </div>
        </div>
      ))}
    </div>
  </>
})

export default OrderDetail