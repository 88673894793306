import React, { forwardRef, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useQuery } from '@apollo/client'
import { Select, Divider } from 'antd'
import eventsQuery from '../graphql/queries/events.gql'

const EventsSelect = forwardRef(({ onChange, onSelect, value, variables, selectAll, style }, meta) => {
  const { brokers, officialPartner, organizers, photoFileDeletedAt, selectedEvent, setFields } = variables ?? {}
  const [search, setSearch] = useState('')
  const { error, loading, data } = useQuery(eventsQuery, {
    variables: {
      search,
      officialPartner,
      brokerIds: brokers?.length ? { in: brokers } : undefined,
      organizerIds: organizers?.length ? { in: organizers } : undefined,
      photoFileDeletedAt: photoFileDeletedAt ? { gte: photoFileDeletedAt } : undefined,
      limit: 999999,
    },
  })
  if (error) {
    return 'Error'
  }
  const events = data?.events ?? []
  const handleOnChange = (value) => {
    onSelect && onSelect({ data: events.filter((event) => value.includes(event._id)) })
    onChange(value)
  }
  const handleSelectAll = () => {
    const eventIds = events.map((item) => item._id)
    onSelect && onSelect({ data: events })
    onChange(eventIds)
  }
  useEffect(() => {
    if (!loading && selectedEvent && !value.length && typeof setFields === 'function') {
      const event = events.find((e) => e._id === selectedEvent)
      onSelect && onSelect({ data: [event] })
      setFields(event)
    }
  }, [events, loading, onSelect, selectedEvent, setFields, value.length])
  return (
    <Select
      mode='multiple'
      loading={loading}
      allowClear
      showSearch
      filterOption={false}
      placeholder={<FormattedMessage id='app.select' defaultMessage='Please Select...' />}
      onChange={handleOnChange}
      onSearch={(v) => setSearch(v)}
      onBlur={() => setSearch()}
      value={value}
      maxTagCount={6}
      dropdownRender={(menu) => (
        <div>
          {menu}
          {selectAll && (
            <div>
              <Divider style={{ margin: '4px 8px' }} />
              <div onMouseDown={(e) => e.preventDefault()} onClick={handleSelectAll} style={{ padding: '4px 8px', cursor: 'pointer' }}>
                <FormattedMessage id='app.allEvents' defaultMessage='All Events' />
                {` (${events.length})`}
              </div>
            </div>
          )}
        </div>
      )}
      style={{ width: 200, ...style }}
    >
      {data?.events?.map((item) => (
        <Select.Option key={item._id} value={item._id}>
          {item.title}
        </Select.Option>
      ))}
    </Select>
  )
})

export default EventsSelect
