import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Table, message, Button, Modal, Spin, Row, Col } from 'antd'
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { inject } from 'mobx-react'
import { useLazyQuery, useQuery } from '@apollo/client'
import couponCodeTypesPaginationQuery from '../graphql/queries/couponCodeTypesPagination.gql'
import couponCodeTypeQuery from '../graphql/queries/couponCodeType.gql'
import moment from 'moment-timezone'

const dateFormat = 'Do MMM YYYY'

const CouponTypeEvents = ({ _id }) => {
  const [open, setOpen] = useState(false)
  const [couponExport, { error, loading, data }] = useLazyQuery(couponCodeTypeQuery, { fetchPolicy: 'network-only' })
  const showModal = () => {
    couponExport({ variables: { _id } })
    setOpen(true)
  }
  if (error) {
    return 'Error'
  }
  if (loading) {
    return <Spin style={{ display: 'block', lineHeight: '100vh' }} />
  }
  return (
    <div>
      <Button type='primary' onClick={showModal}>
        <FormattedMessage id='app.event' defaultMessage='Event' />
      </Button>
      <Modal
        title={<FormattedMessage id='app.eventLists' defaultMessage='Event Lists' />}
        open={open}
        onCancel={() => setOpen(false)}
        width={600}
        bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(70vh)' }}
        style={{ top: '4rem' }}
        footer={[
          <Button key='back' onClick={() => setOpen(false)}>
            <FormattedMessage id='app.action.close' defaultMessage='Close' />
          </Button>,
        ]}
      >
        {data?.couponCodeType?.events?.length ? (
          data?.couponCodeType?.events?.map((event) => (
            <Row>
              <Col sm={24} md={18}>
                {event.title}
              </Col>
              <Col sm={24} md={6}>
                {moment(event.startDate).format(dateFormat)}
              </Col>
            </Row>
          ))
        ) : (
          <FormattedMessage id='app.allEvents' defaultMessage='All Events' />
        )}
      </Modal>
    </div>
  )
}

const CouponTypes = inject('router')(({ router }) => {
  const { location } = router
  const { p: page, s: search } = location.query
  const { error, loading, data } = useQuery(couponCodeTypesPaginationQuery, {
    variables: {
      name: search ? `/${search}/i` : '',
      page: parseInt(page, 10),
      perPage: 10,
    },
    fetchPolicy: 'network-only',
  })

  const handlePagination = (page) => {
    location.query = { ...location.query, p: page }
  }

  if (error) {
    return message.error(error)
  }

  if (loading) {
    return <Spin style={{ display: 'block', lineHeight: '100vh' }} />
  }

  const { items, pageInfo } = data?.couponCodeTypesPagination || {}
  const { currentPage, itemCount, perPage } = pageInfo || {}

  return (
    <>
      <Table
        rowKey='_id'
        scroll={{ x: true }}
        dataSource={items}
        loading={loading}
        pagination={{
          perSize: perPage,
          current: currentPage,
          total: itemCount,
          onChange: handlePagination,
        }}
      >
        <Table.Column
          title={<FormattedMessage id='app.couponType' defaultMessage='Coupon type' />}
          key='name'
          dataIndex={['name']}
          render={(name, { _id }) => <Link to={`/coupons?couponTypeId=${_id}`}>{name}</Link>}
        />

        <Table.Column title={<FormattedMessage id='app.discountType' defaultMessage='Discount type' />} key='discountType' dataIndex={['discountType']} />

        <Table.Column title={<FormattedMessage id='app.discount' defaultMessage='Discount' />} key='discount' dataIndex={['discount']} />

        <Table.Column title={<FormattedMessage id='app.couponMinItems' defaultMessage='Coupon min items' />} key='minItems' dataIndex={['minItems']} />

        <Table.Column title={<FormattedMessage id='app.couponMaxItems' defaultMessage='Coupon max items' />} key='maxItems' dataIndex={['maxItems']} />

        <Table.Column
          title={<FormattedMessage id='app.startDate' defaultMessage='Start Date' />}
          key='validityPeriod.start'
          dataIndex={['validityPeriod', 'start']}
          render={(date) => moment(date).format(dateFormat)}
        />

        <Table.Column
          title={<FormattedMessage id='app.endDate' defaultMessage='End Date' />}
          key='validityPeriod.end'
          dataIndex={['validityPeriod', 'end']}
          render={(date) => moment(date).format(dateFormat)}
        />

        <Table.Column
          title={<FormattedMessage id='app.eventLists' defaultMessage='Event Lists' />}
          key='events'
          dataIndex={['_id']}
          render={(_id) => <CouponTypeEvents _id={_id} />}
        />

        <Table.Column
          title={<FormattedMessage id='app.orderCoupons' defaultMessage='Order Coupons' />}
          key='orderCoupons'
          dataIndex={['_id']}
          render={(_id, { couponUsedCount }) => <Link to={`/coupon-types/${_id}/coupons`}>{couponUsedCount}</Link>}
        />

        <Table.Column
          title={<FormattedMessage id='app.edit' defaultMessage='Edit' />}
          key='edit'
          dataIndex='_id'
          render={(_id) => <Link to={`/coupon-types/${_id}/edit`}><Button icon={<EditOutlined />} /></Link>}
        />

        <Table.Column
          title={<FormattedMessage id='app.generateCoupons' defaultMessage='Generate Coupons' />}
          key='add'
          dataIndex={['_id']}
          render={(_id) => (
            <Link to={`/coupons/generate?couponTypeId=${_id}`}>
              <Button icon={<PlusCircleOutlined />} />
            </Link>
          )}
        />
      </Table>
    </>
  )
})

export default CouponTypes
