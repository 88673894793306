import { useMutation } from '@apollo/client'
import { Button, Form, Input, InputNumber, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { FormattedNumber, FormattedMessage, useIntl } from 'react-intl'
import { compose } from 'recompose'
import { inject } from 'mobx-react'
import createTransferRequestMutation from '~/graphql/mutations/createTransferRequest.gql'
import EventsSelect from './EventsSelect'
import config from '../config'

const FEE = config.transferFee
const MINIMUM_AMOUNT = config.minimumTransferRequestAmount
const MAXIMUM_AUTO_APPROVED_AMOUNT = 50000

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
}

const TransferRequestForm = ({ adminUser, role, router, user: currentUser, userId }) => {
  const { location } = router
  const { e } = location.query
  const [form] = Form.useForm()
  const { getFieldValue, resetFields, setFieldsValue } = form
  const intl = useIntl()
  const [events, setEvents] = useState()
  const [submitting, setSubmitting] = useState(false)
  const [createTransferRequest] = useMutation(createTransferRequestMutation)
  const handleConfirm = () => {
    const {
      record: { amount },
    } = getFieldValue()
    Modal.confirm({
      title: intl.formatMessage({ id: 'app.confirm', defaultMessage: 'Confirm' }),
      content: intl.formatMessage(
        {
          id: config.platform === 'heyjom' ? 'app.confirmTransferCreateHeyjom' : 'app.confirmTransferCreate',
        },
        {
          amount,
          fee: FEE,
          total: amount - FEE,
          num: ([amount]) =>
            intl.formatNumber(Number(amount), { minimumAmount: 2, style: 'currency', currency: config.currency, currencyDisplay: 'code' }),
          b: (chunks) => <b>{chunks}</b>,
        }
      ),
      onOk: handleSubmit,
      style: { whiteSpace: 'pre-wrap' },
    })
  }
  const handleEvents = ({ data }) => {
    setEvents(data)
  }
  const handleSetFields = (event) => {
    setFieldsValue({ record: { eventIds: [event._id] } })
  }
  const handleSubmit = async () => {
    setSubmitting(true)
    const {
      record: { amount, eventIds, note },
    } = getFieldValue()
    try {
      await createTransferRequest({
        variables: {
          amount,
          note,
          role,
          userId,
          ...(role === 'broker' && { eventIds }),
        },
      })
      resetFields()
      setSubmitting(false)
      router.push('/transfers')
    } catch (err) {
      setSubmitting(false)
      console.error(err)
    }
    // TODO: redirect to /transfers
  }
  const { balance, bankAccount } = adminUser ?? {}
  const { total } = balance ?? {}
  const { name, number } = bankAccount ?? {}
  const eventIds = events?.map((event) => event._id) ?? []
  const brokerShareAmount = events?.reduce((prev, curr) => prev + curr.brokerShareAmount, 0) ?? 0
  const maxAmount =
    role === 'broker' ? brokerShareAmount : ['superadmin'].includes(currentUser.role) ? total : Math.min(total, MAXIMUM_AUTO_APPROVED_AMOUNT)
  useEffect(() => {
    setFieldsValue({ record: { amount: maxAmount } })
  }, [maxAmount, setFieldsValue])
  return (
    <Form form={form} onFinish={handleConfirm}>
      <Form.Item {...formItemLayout} label={<FormattedMessage id='app.myBalance' defaultMessage='My Balance' />}>
        <FormattedNumber value={total} minimumFractionDigits={2} />
      </Form.Item>
      <Form.Item {...formItemLayout} label={<FormattedMessage id='app.accountNumber' defaultMessage='My Balance' />}>
        {number ?? ''}
      </Form.Item>
      <Form.Item {...formItemLayout} label={<FormattedMessage id='app.accountName' defaultMessage='My Balance' />}>
        {name ?? ''}
      </Form.Item>
      {role === 'broker' && (
        <Form.Item
          {...formItemLayout}
          name={['record', 'eventIds']}
          label={<FormattedMessage id='app.event' defaultMessage='Event' />}
          initialValue={eventIds}
        >
          <EventsSelect
            onSelect={handleEvents}
            setFields={handleSetFields}
            variables={{ setFieldsValue, ...(e && !events && { selectedEvent: e }), brokers: [adminUser._id] }}
          />
        </Form.Item>
      )}
      <Form.Item noStyle shouldUpdate>
        {() => (
          <Form.Item
            {...formItemLayout}
            name={['record', 'amount']}
            label={<FormattedMessage id='app.amountRequested' defaultMessage='Amount' />}
            initialValue={maxAmount}
          >
            <InputNumber disabled={role === 'broker'} max={maxAmount} min={MINIMUM_AMOUNT} precision={2} />
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item {...formItemLayout} label={<FormattedMessage id='app.deductTransferFee' defaultMessage='Deduct Transfer Fee' />}>
        <FormattedNumber value={-FEE} minimumFractionDigits={2} style='currency' currency={config.currency} /> {/* eslint-disable-line react/style-prop-object */}
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {() => (
          <Form.Item
            {...formItemLayout}
            label={<FormattedMessage id='app.amountToReceive' defaultMessage='Amount to be received' tagName='strong' />}
          >
            <strong>
              <FormattedNumber
                value={getFieldValue(['record', 'amount']) - FEE}
                minimumFractionDigits={2}
                style='currency' /* eslint-disable-line react/style-prop-object */
                currency={config.currency}
              />
            </strong>
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item {...formItemLayout} name={['record', 'note']} label={<FormattedMessage id='app.note' defaultMessage='Note' />}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item wrapperCol={{ xs: { span: 24 }, sm: { span: 18, offset: 3 } }} style={{ whiteSpace: 'pre-line' }}>
        <FormattedMessage
          id={config.platform === 'heyjom' ? 'app.remark.transferRequestHeyjom' : 'app.remark.transferRequest'}
          values={{
            // commissionRate: adminUser.commissionRate * 100,
            minimumAmount: <FormattedNumber value={MINIMUM_AMOUNT} style='currency' currency={config.currency} />, /* eslint-disable-line react/style-prop-object */
            transferFee: <FormattedNumber value={FEE} style='currency' currency={config.currency} />, /* eslint-disable-line react/style-prop-object */
            maximumAutoApprovedAmount: <FormattedNumber value={MAXIMUM_AUTO_APPROVED_AMOUNT} style='currency' currency={config.currency} />, /* eslint-disable-line react/style-prop-object */
          }}
        />
      </Form.Item>
      <Form.Item shouldUpdate wrapperCol={{ xs: { span: 24 }, sm: { span: 8, offset: 8 } }}>
        {() => (
          <Button
            loading={submitting}
            type='primary'
            htmlType='submit'
            disabled={getFieldValue(['record', 'amount']) < MINIMUM_AMOUNT ? true : false}
          >
            <FormattedMessage id='app.submitRequest' defaultMessage='Submit Request' />
          </Button>
        )}
      </Form.Item>
    </Form>
  )
}

export default compose(inject('router'), inject('user'))(TransferRequestForm)
