import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Select } from 'antd'
import config from '../config'

// const paymentMethodInput = [
//     { _id: 0, name: <FormattedMessage id='app.allPaymentMethod' defaultMessage='All Payment Method' /> },
//     { _id: 1, method: 'omise_card', name: 'Credit/Debit Card' },
//     { _id: 2, method: 'omise_truemoney', name: 'Truemoney' },
//     // { _id: 3, method: 'omise_netbank', name: 'Netbank' },
//     { _id: 3, method: 'linepay_web', name: 'Linepay' },
//     { _id: 4, method: 'scb_qr', name: 'QR Code' },
//     // {_id: 6, method:'OMISE', name: 'OMISE', input:'OMISE'},
//   ]

const paymentMethodInput = {
  omise_card: "Credit/Debit Card",
  omise_truemoney: "Truemoney",
  omise_netbank: "Netbank",
  omise_duitnow_obw: "DuitNow Online Banking/Wallets",
  omise_touchngo: "Touch 'n Go",
  omise_boost: "Boost",
  omise_points_citi: "Rewards Points",
  midtrans_card: "Other Debit/Credit Card",
  midtrans_card_maybank : "Maybank Debit / Credit Card",
  midtrans_qris: "Pay with QRIS",
  linepay_web: "Linepay",
  scb_qr: "QR Code",
  OMISE: "OMISE"  }


const PaymentMethod = ({ onChange, value, style }) => {

  return (
    <Select
      allowClear
      // showSearch
      filterOption={false}
      placeholder={<FormattedMessage id='app.paymentMethod' defaultMessage='Payment Method' />}
      onChange={onChange}
      // onSearch={(v) => setSearch(v)}
      // onBlur={() => setSearch()}
      value={value}
      // maxTagCount={6}
      style={{ width: 200, ...style }}
    >
      {config.paymentMethods?.map((item) => (
        <Select.Option key={item._id} value={item}>
        {paymentMethodInput[item]}
        </Select.Option>
        ))}
    </Select>
  )
}

export default PaymentMethod
