import React, { useState } from 'react'
import path from 'path'
import url from 'url'
import { Upload, Button } from 'antd'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import cloudUploadMutation from '~/graphql/mutations/cloudUpload.gql'
// import loadImage from 'blueimp-load-image'
import axios from 'axios'
import _get from 'lodash/get'
import { FormattedMessage } from 'react-intl'

const CloudUploader = React.forwardRef(({ onChange, value, provider = 'gs', path: fullPath, customFileName, disabled, accept }, ref) => {
  const [loading, setLoading] = useState(false)
  const [upload] = useMutation(cloudUploadMutation)

  const customRequest = async ({ file }) => {
    setLoading(true)
    const fileName = customFileName && customFileName(file.name)
    const response = await upload({
      variables: {
        provider,
        path: fullPath,
        mimeType: file.type,
        fileName
      }
    })
    const { uploadUrl, downloadUrl } = _get(response, 'data.upload', {})
    if (uploadUrl) {
      await axios.put(uploadUrl, file, {
        headers: {
          'Content-Type': file.type,
          ...(provider === 'gs' && {
            'x-goog-acl': 'public-read'
          })
        }
      })

      setLoading(false)
      onChange(downloadUrl)
    }
  }
  const onRemove = () => {
    onChange()
  }
  const fileList = value ? [{
    uid: value,
    name: path.basename(url.parse(value).pathname),
    status: 'done',
    url: value
  }] : []

  // const { value } = this.props
  return (
    <Upload ref={ref} accept={accept} customRequest={customRequest} listType="picture" defaultFileList={[...fileList]} fileList={fileList} onRemove={onRemove}>
      {loading ? (
        <Button disabled icon={<LoadingOutlined />}>
          Uploading...
        </Button>
      ) : value ? (
        <Button icon={<UploadOutlined />} disabled={disabled}>
          <FormattedMessage id='app.reUpload' defaultMessage='Re-Upload' />
        </Button>
      ) : (
        <Button icon={<UploadOutlined />} loading={loading} disabled={disabled}>
          <FormattedMessage id='app.upload' defaultMessage='Upload' />
        </Button>
      )}
    </Upload>
  )
})

export default CloudUploader
