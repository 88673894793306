import React from 'react'
import { useLazyQuery } from '@apollo/client'
import { Button, Spin } from 'antd'
import * as XLSX from 'xlsx'

const ExportData = ({ children, exportedData, fileName = 'unknown.xlsx', filter, lazyQuery, sheetNames = ['unknown'], style }) => {
  const [query, { error, loading }] = useLazyQuery(lazyQuery, { fetchPolicy: 'network-only' })
  const handleExport = async () => {
    const { data } = await query({
      variables: {
        ...filter,
        limit: 100000,
        perPage: 100000,
      },
    })
    const exportData = exportedData(data)
    exportToCSV([exportData], fileName, sheetNames)
  }
  const exportToCSV = (apiData, fileName = 'unknown.xlsx', SheetNames) => {
    const ws = apiData.map((item) => XLSX.utils.json_to_sheet(item))
    const Sheets = {}
    SheetNames.forEach((item, index) => (Sheets[item] = ws[index]))
    const wb = { Sheets, SheetNames }
    XLSX.writeFile(wb, fileName)
  }

  if (error) {
    return 'Error'
  }
  if (loading) {
    return <Spin style={{ textAlign: 'center' }} />
  }

  return (
    <Button type='primary' size='small' onClick={() => handleExport()} style={style}>
      {children}
    </Button>
  )
}

export default ExportData
