import React, { useState } from "react";
import { Upload } from "antd";
import {FileImageFilled } from '@ant-design/icons'
import axios from "axios";
import loadImage from "blueimp-load-image";
// import dataURLtoBlob from 'blueimp-canvas-to-blob'
import { useMutation } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import _get from "lodash/get";
import photoS3UploadQuery from "~/graphql/mutations/photoS3Upload.gql";

const MAX_DIMENSION = 720;
const IMAGE_QUALITY = 0.8;

// const SESSION_CONSENT_KEY = "privacy_consent";

const dataURLtoBlob = (dataURL) => {
  const BASE64_MARKER = ";base64,";
  // let parts, contentType, raw
  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    const [prefix, suffix] = dataURL.split(",");
    const contentType = prefix.split(":")[1];
    const raw = decodeURIComponent(suffix);

    return new Blob([raw], { type: contentType });
  }

  const [prefix, suffix] = dataURL.split(BASE64_MARKER);
  const contentType = prefix.split(":")[1];
  const raw = window.atob(suffix);
  const rawLength = raw.length;

  const uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: contentType });
};

const resizeImg = async (file, { width, height }, quolity = 1) => {
  return new Promise((resolve, reject) => {
    loadImage(
      file,
      (canvas) => {
        if (canvas.type === "error") {
          return Promise.reject(new Error("Incorrect Image"));
        }
        const dataUrl = canvas.toDataURL("image/jpeg", quolity);
        const blob = dataURLtoBlob(dataUrl);
        // blob.name = file.name
        // blob.lastModified = Date.now()
        resolve(blob);
      },
      {
        maxWidth: width,
        maxHeight: height,
        contain: true,
        orientation: true,
        canvas: true,
      }
    );
  });
};

const FaceUpload = ({ onCompleted, awsAccount, setFileName }) => {
  const [getUpload] = useMutation(photoS3UploadQuery);
  const [loading, setLoading] = useState(false);
  // const uploadRef = useRef()
  // const consentAgreed = JSON.parse(sessionStorage.getItem(SESSION_CONSENT_KEY));
  // const [consentModalOpen, setConsentModalOpen] = useState(!consentAgreed);
  const handleChange = (e) => {
    // console.log(e)
    setFileName(e.file.name);
  };
  const customRequest = async ({ file }) => {
    setLoading(true);
    const { data } = await getUpload({ variables: { awsAccount } });
    const uploadUrl = _get(data, "photoS3Upload.url");
    const id = _get(data, "photoS3Upload._id");
    // console.log({ data, id })
    if (uploadUrl) {
      const blob = await resizeImg(
        file,
        { width: MAX_DIMENSION, height: MAX_DIMENSION },
        IMAGE_QUALITY
      );
      await axios.put(uploadUrl, blob, {
        headers: { "Content-Type": "image/jpeg" },
      });
      setLoading(false);
      onCompleted(id);
    }
    // console.log({ response})
  };

  // const handleModal = (agreed) => {
  //   setConsentModalOpen(false);
  //   sessionStorage.setItem(SESSION_CONSENT_KEY, JSON.stringify(agreed));
  // };
  return (
    <>
      <Upload.Dragger style={{ borderRadius: 8 , background:'#fff', width: '100%', display: 'flex', justifyContent: 'center'}}
        onChange={handleChange}
        customRequest={customRequest}
        showUploadList={false}
      >
        <div loading={loading}>
          <div style={{textAlign: 'center', width: '100%'}}>
            <FileImageFilled style={{fontSize: 50, color:'#00A8DE'}}/>
          <div style={{margin: '5px'}}><FormattedMessage id="app.upload.note.1"/></div>
          </div>
        </div> 
        {/* <Button
          icon={<UploadOutlined />}
          type="dashed"
          loading={loading}
          style={{
            // width: "300px",
            // background: "#FAFAFA",
            background: "#FFFFFF",
            border: "1px solid #D4D4D4",
            // boxSizing: "border-box",
            // boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.1)",
            borderRadius: "4px",
            // padding: "10px",
            // height: "40px",
          }}
        >
          <FormattedMessage id="app.faceSearch" defaultMessage="Face Search" />
        </Button> */}

      </Upload.Dragger>
      {/* <Upload onChange={handleChange} customRequest={customRequest} showUploadList={false} disabled={!consentAgreed} >
        <Button icon={<UploadOutlined />} type="dashed" size="large" loading={loading} style={{ width: '100%', background: '#FAFAFA' }} ><FormattedMessage  id="app.faceSearch"
        defaultMessage="Face Search"/></Button>
    </Upload > */}
    </>
  );
};
export default FaceUpload;
