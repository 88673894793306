import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { Form, Table, Button, Typography, Popconfirm, Input, Spin, message, Modal, Row, Col, Collapse } from 'antd'
import { CheckCircleOutlined, InfoCircleOutlined, ExclamationCircleOutlined, WarningOutlined, CloseCircleFilled, CloseOutlined, FileTextOutlined, PlusOutlined } from '@ant-design/icons'

import { inject } from 'mobx-react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { compose } from 'recompose'
import { CSVLink } from 'react-csv'
import moment from 'moment'
// import Linkify from 'react-linkify'
import _get from 'lodash/get'
import MaintenanceMessage from '~/components/MaintenanceMessage'
import eventQuery from '../graphql/queries/event.gql'
import eventsQuery from '../graphql/queries/events.gql'
import createUploadMutation from '../graphql/mutations/createUpload.gql'
import reviewUploadMutation from '../graphql/mutations/reviewUpload.gql'
import endRegistrationMutation from '../graphql/mutations/endRegistration.gql'
import PhotoUploadTools from './PhotoUploadTools'
import PhotoUploadRequest from './PhotoUploadRequest'

import AdminUserSelect from './AdminUserSelect'
import EventCancelButton from './EventCancelButton'

const PhotoUpload = compose(
  inject('user'),
  injectIntl
)(({ intl, match, user }) => {
  const [form] = Form.useForm()
  const { resetFields } = form
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const { eventId } = match?.params ?? {}
  const {
    error: eventError,
    loading: eventLoading,
    data: eventData,
  } = useQuery(eventQuery, {
    variables: {
      _id: eventId,
    },
  })
  const {
    error: eventsError,
    loading: eventsLoading,
    // data: eventsData,
  } = useQuery(eventsQuery, {
    variables: {
      // uploadApprovalRequired: true,
      OR: [{ registrationMethod: 'auto' }, { registrationMethod: 'manual' }],
      startDate: eventData?.event?.startDate,
      _id: eventId,
    },
    skip: !!eventData,
    fetchPolicy: 'network-only',
    refetchQueries: [
      {
        query: eventQuery,
      },
    ],
  })
  const [createUpload] = useMutation(createUploadMutation, {
    refetchQueries: [{ query: eventQuery, variables: { _id: eventId } }],
  })
  const [reviewUpload] = useMutation(reviewUploadMutation, {
    refetchQueries: [{ query: eventQuery, variables: { _id: eventId } }],
  })
  const [endRegistration] = useMutation(endRegistrationMutation, {
    refetchQueries: [{ query: eventQuery, variables: { _id: eventId } }],
  })
  const uploadApprovalMessage = useRef()
  const uploadRow = (upload, index) => {
    return {
      ลำดับที่: (index + 1).toString(),
      ชื่อที่แสดง: upload.photographer.displayName,
      ชื่อผู้ใช้: upload.photographer.username,
      'ชื่อ-นามสกุล': upload.photographer.profile.firstName + ' ' + upload.photographer.profile.lastName,
      เลขประจำตัวประชาชน: upload.photographer.profile.nationalId,
      'ชื่อ Facebook Page ': upload.photographer.photographerProfile ? upload.photographer.photographerProfile.facebookPageName : '',
      เบอร์โทรศัพท์: upload.photographer.profile.phone,
      อีเมล: upload.photographer.profile.email,
      ขนาดภาพที่ขาย: upload.photoSizes.join(','),
      จำนวนภาพถ่าย: upload.photoCount.toString(),
      อนุมัติการอัปโหลด: upload.approved ? 'อนุมัติ' : upload.approved === null ? 'รออนุมัติ' : 'ปฏิเสธ',
    }
  }
  const registrationMethodContent = {
    'free': <>Queue</>,
    'auto': <>Lotto</>,
    'manual': <>Custom</>
  };
  const handleReviewUpload = async ({ uploadId, approved, downloadMode }) => {
    await reviewUpload({
      variables: {
        _id: uploadId,
        approved,
        approvalMessage: uploadApprovalMessage.current.resizableTextArea.textArea.value,
        downloadMode,
      },
    })
  }
  const handleEndRegistration = async () => {
    await endRegistration()
  }
  const handleSubmit = async (values) => {
    const { photographerId } = values
    try {
      await createUpload({
        variables: {
          approved: true,
          eventId,
          photographerId,
        },
      })
      resetFields()
    } catch (err) {
      message.error(intl.formatMessage({ id: `api.errors.${err.message}`, defaultMessage: 'Error' }))
    }
  }

  // const handleAdminUserSelect = (userId) => {
  //   setUserId(userId)
  // }

  if (eventLoading || eventsLoading) {
    return <Spin style={{ display: 'block', lineHeight: '100vh' }} />
  }
  if (eventError || eventsError) {
    return 'Error...'
  }

  const { event } = eventData ?? {}
  const uploadTable = event.uploads.map((upload, index) => uploadRow(upload, index))
  const table = (
    <Table rowKey={'_id'} dataSource={event.uploads} pagination={false} scroll={{ x: true }} style={{ marginTop: '1em' }}>
      <Table.Column
        key='photographer'
        title={<FormattedMessage id='app.photographer' defaultMessage='Photographer' />} // TODO: i18n: ช่างภาพ
        dataIndex={['photographer', 'displayName']}
        render={(displayName, { photographer }) => `${displayName} (@${photographer.username})`}
      />
      <Table.Column
        key='photographerNumber'
        title={<FormattedMessage id='app.photographer' defaultMessage='Photographer' />} // TODO: i18n: ช่างภาพ
        dataIndex={['photographer', 'number']}
        render={(number) => number > 0 && `TR${`${number}`.padStart(4, 0)}`}
      />
      <Table.Column
        key='photographerContact'
        title={<FormattedMessage id='app.contactInfo' defaultMessage='Contact Information' />} // TODO: i18n: ข้อมูลติดต่อ
        dataIndex={['photographer', 'profile']}
        render={(profile) => (
          <div style={{ whiteSpace: 'pre-line' }}>
            {`${profile.firstName} ${profile.lastName}
              ${profile.phone}
              ${profile.email}
            `}
          </div>
        )}
      />
      <Table.Column
        key='photoSizes'
        title={<FormattedMessage id='app.photoSizes' defaultMessage='Photo Sizes' />} // TODO: i18n: ข้อมูลติดต่อ
        dataIndex={['photoSizes']}
        render={(sizes) => sizes.join(', ')}
      />
      <Table.Column
        key='photoCount'
        title={<FormattedMessage id='app.photoUploaded' defaultMessage='Photo Uploaded' />} // TODO: i18n: ข้อมูลติดต่อ
        dataIndex={['photoCount']}
      />
      <Table.Column
        key='approved'
        title={<FormattedMessage id='app.uploadApproval' defaultMessage='Upload Approval' />} // TODO: i18n: ข้อมูลติดต่อ
        dataIndex={['approved']}
        render={(approved, { _id: uploadId, approvalMessage, downloadMode }) =>
        ['superadmin', 'admin', 'operation_admin', 'accounting'].includes(user.role) || (event.createdByUserId === user._id && user.role === 'broker') || (event.createdByUserId === user._id && user.role === 'photographer') ? (
            <Popconfirm
              icon={<InfoCircleOutlined />}
              title={
                <div>
                  <Input.TextArea ref={uploadApprovalMessage} defaultValue={approvalMessage} placeholder={intl.formatMessage({ id: 'app.remarks', defaultMessage: 'Remarks' })} />
                  {/* <Checkbox
                    checked={downloadMode === 'free'}
                    onChange={() => 
                      handleReviewUpload({
                        uploadId,
                        approved: downloadMode !== 'free',
                        downloadMode: downloadMode === 'free' ? 'priced' : 'free',
                      })
                    }
                  >
                    Official Photographer
                  </Checkbox> */}
                </div>
              }
              okText={<FormattedMessage id='app.approve' defaultMessage='Approve' />}
              cancelText={<FormattedMessage id='app.reject' defaultMessage='Reject' />}
              cancelButtonProps={{ type: 'primary', danger: true }}
              okButtonProps={{style: { backgroundColor: '#28C61A' }}}
              onConfirm={() => handleReviewUpload({ uploadId, approved: true, message: _get(uploadApprovalMessage, 'current.textAreaRef.value') })}
              onCancel={() => handleReviewUpload({ uploadId, approved: false, message: _get(uploadApprovalMessage, 'current.textAreaRef.value') })}
            >
              {approved === true && (
                <Button style={{background: '#28C61A', color: 'white'}}>
                  <CheckCircleOutlined /> <FormattedMessage id='app.approved' defaultMessage='Approved' />
                </Button>
              )}
              {approved === false && (
                <Button type='primary' danger>
                  <WarningOutlined /> <FormattedMessage id='app.rejected' defaultMessage='Rejected' />
                </Button>
              )}
              {approved === null && (
                <Button>
                  <InfoCircleOutlined /> <FormattedMessage id='app.reviewUpload' defaultMessage='Review Upload' />
                </Button>
              )}
            </Popconfirm>
          ) : (
            <div>
              {approved === true && (
                <Button style={{background: '#28C61A', color: 'white'}}>
                  <CheckCircleOutlined /> <FormattedMessage id='app.approved' defaultMessage='Approved' />
                </Button>
              )}
              {approved === false && (
                <Button type='primary' danger>
                  <WarningOutlined /> <FormattedMessage id='app.rejected' defaultMessage='Rejected' />
                </Button>
              )}
              {approved === null && (
                <Button>
                  <InfoCircleOutlined /> <FormattedMessage id='app.reviewUpload' defaultMessage='Review Upload' />
                </Button>
              )}
            </div>
          )
        }
      />
    </Table>
  )

  return (
    <MaintenanceMessage>
      <div style={{fontSize: 20, margin: '10px'}}><FormattedMessage id="app.eventReq"/></div>
      <div style={{
        justifyContent: 'center',
        background: "white",
        border: "1px solid LightGrey",
        borderRadius: "25px",
        padding: "15px",
        margin: "10px 15px"
      }}>
        <Typography.Title level={3} style={{ textAlign: 'rigth' }}>
          {event.title}
        </Typography.Title>
        <div style={{fontSize: 15, display: 'flex'}}>
          <FormattedMessage id='app.eventDate'/> : <div style={{color: '#BD188B'}}>{moment(event.startDate).format('Do MMM YYYY')}</div>
        </div>
        <div style={{fontSize: 15, display: 'flex'}}>
          <FormattedMessage id="app.registrationStartsEndsAt" defaultMessage="Registration Starts - Ends" /> : <div style={{color: '#BD188B'}}>{event.registrationStartsAt && event.registrationEndsAt && <>{moment(event.registrationStartsAt).format('Do MMM YYYY')} - { moment(event.registrationEndsAt).format('Do MMM YYYY')}</>}</div>
        </div>
        <div style={{fontSize: 15 , display: 'flex'}}>
          <FormattedMessage id="app.startUploadOn" defaultMessage="startUploadOn" /> : <div style={{color: '#BD188B'}}>{event.uploadableAt && moment(event.uploadableAt).format('Do MMM YYYY')}</div>
        </div>
        <div style={{fontSize: 15, display: 'flex'}}>
          <FormattedMessage id="app.typePhotographer" defaultMessage="typePhotographer" /> : <div style={{color: '#BD188B'}}>{registrationMethodContent[event.registrationMethod]}</div>
        </div>
        <div style={{margin: '10px 0'}}>
       <Row gutter={[4,4]} type='flex'>
          {event.registrationMethod !== 'manual' &&<Col xs={24} sm={8}><Button style={{width: '100%', height: 50, fontSize: 15, textAlign: 'left'}}><div style={{display:'flex'}}><FormattedMessage id='app.photographerLimit1'/>:<div style={{color: '#BD188B'}}>{event.photographerNumber}</div></div></Button></Col>}
          {event.registrationMethod === 'free' && <Col xs={24} sm={8}><Button style={{width: '100%', height: 50, fontSize: 15, textAlign: 'left'}}><div style={{display:'flex'}}><FormattedMessage id='app.photogerapherRe'/>:<div style={{color: '#BD188B'}}>{event.approvedUploadCount}</div></div></Button></Col>}
          {(event.registrationMethod === 'auto' || event.registrationMethod === 'manual') && <Col xs={24} sm={8}><Button style={{width: '100%', height: 50, fontSize: 15, textAlign: 'left'}}><div style={{display:'flex'}}><FormattedMessage id='app.photogerapherRe'/>:<div style={{color: '#BD188B'}}> {event.uploadCount}</div></div></Button></Col>}
          {(user.role !== 'admin' && event.registrationMethod !== 'manual') && <Col xs={24} sm={8}>{event.userUpload ? (<>
          {event.userUpload.approved === true && <EventCancelButton
                event={event}
                user={user}
                registrable
                // registrable={
                //   eventsData?.events &&
                //   eventsData?.events.filter((event) => {
                //     return event.userUpload !== null && event.userUpload.approved !== false
                //   }).length < 2
                // }
                agreementAccept={true /* this.state.agreementAccept */}
                disabled={
                  (moment(event.registrationEndsAt) < moment()) 
                  // ||(event.registrationMethod === 'free' && moment(event.startDate).add(-1, 'days') < moment())
                }
              />}
            {event.userUpload.approved === false && (
              <Button type='primary' icon={<ExclamationCircleOutlined />} danger style={{width: '100%', height: 50, fontSize: 18, color:'white'}}>
                <FormattedMessage id='app.uploadRequestRejected' defaultMessage='Upload Request Rejected' />
              </Button>
              // <Result
              //   status='warning'
              //   title={<FormattedMessage id='app.uploadRequestRejected' defaultMessage='Upload Request Rejected' />}
              //   subTitle={event.userUpload.approvalMessage}
              //   extra={
              //     <Link to={`/events`}>
              //       <Button icon={<LeftOutlined />}>
              //         <FormattedMessage id='app.allEvents' defaultMessage='All Events' />
              //       </Button>
              //     </Link>
              //   }
              // />
            )}
            {event.userUpload.approved === null && (
              <div> <EventCancelButton
               event={event}
               user={user}
               registrable
               // registrable={
               //   eventsData?.events &&
               //   eventsData?.events.filter((event) => {
               //     return event.userUpload !== null && event.userUpload.approved !== false
               //   }).length < 2
               // }
               agreementAccept={true /* this.state.agreementAccept */}
               disabled={
                 (event.registrationMethod !== 'manual' && moment(event.registrationEndsAt) < moment()) 
                 // ||(event.registrationMethod === 'free' && moment(event.startDate).add(-1, 'days') < moment())
               }
             />
              {/* <Result
                status='info'
                title={<FormattedMessage id='app.uploadRequestPending' defaultMessage='Upload Request Pending Approval' />}
                extra={
                  <Link to={`/events`}>
                    <Button icon={<LeftOutlined />}>
                      <FormattedMessage id='app.allEvents' defaultMessage='All Events' />
                    </Button>
                  </Link>
                }
              /> */}
              </div>
            )}
              </>) : (<PhotoUploadRequest
                event={event}
                user={user}
                registrable
                // registrable={
                //   event.registrationMethod === 'free' ||
                //   (eventsData?.events &&
                //     eventsData?.events.filter((item) => {
                //       return item.userUpload !== null && item.userUpload.approved !== false
                //     }).length < 2)
                // }
                agreementAccept={true}
                approvalRequired={event.registrationMethod === 'auto' || event.registrationMethod === 'manual'}
                showModal={showModal}
              />)}
          </Col>}
        </Row>
        </div>
      </div>
      {event?.uploadNotice && <Collapse defaultActiveKey={event.userUpload ? [] : ['conditions']} 
      style={{
        // justifyContent: 'center',
        background: "white",
        // border: "1px solid LightGrey",
        borderRadius: "15px",
        padding: "0px 15px",
        margin: "0px 15px"
      }}>
          <Collapse.Panel header={<FormattedMessage id='app.termsAndConditions' defaultMessage='Terms and Conditions' />} key='conditions'>
            <p style={{ whiteSpace: 'pre-line' }}>{event?.uploadNotice}</p>
          </Collapse.Panel>
        </Collapse>}
      <div style={{
        justifyContent: 'center',
        background: "white",
        border: "1px solid LightGrey",
        borderRadius: "25px",
        padding: "15px",
        margin: "10px 15px"
      }}>
        <div style={{fontSize: 18}}><FormattedMessage id='app.coverImage'/></div>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <img src={event.coverUrl} alt={event.title} style={{ maxHeight: '400px', maxWidth: '100%' }} />
        </div>
      </div>

      <Modal open={isModalOpen} closable={false} maskClosable={false} footer={<Link to={`/events`}><Button type="primary" key="back" onClick={handleCancel}>
            Close
          </Button></Link>}>
        <div style={{ textAlign: 'center'}}><CloseCircleFilled style={{ fontSize: '100px', color: '#FB8226'}} />
        <p style={{ fontSize: '20px'}} >ขณะนี้ระบบรับสมัครช่างภาพเต็มแล้ว</p></div>
      </Modal>

        {/* <div style={{ width: '100%', textAlign: 'center' }}>
          <img src={event.coverUrl} alt={event.title} style={{ maxHeight: '400px', maxWidth: '100%' }} />
        </div> */}
        {/* <Typography.Paragraph style={{ whiteSpace: 'pre-line' }}>
          <Linkify
            componentDecorator={(href, text, key) => (
              <a href={href} key={key} target='_blank' style={{ textDecoration: 'underline' }}>
                {text}
              </a>
            )}
          >
            {event.uploadNotice}
          </Linkify>
        </Typography.Paragraph> */}
    
        {(['superadmin', 'admin', 'operation_admin', 'accounting'].includes(user.role) || (event.createdByUserId === user._id && ['broker'].includes(user.role)) || (event.createdByUserId === user._id && user.role === 'photographer')) ? (
          <div style={{ 
            justifyContent: 'center',
            background: "white",
            border: "1px solid LightGrey",
            borderRadius: "25px",
            padding: "15px",
            margin: "10px 15px"
            }}>
              {event?.userUpload?.approved === true && <div style={{
              margin: "15px"
              }}><PhotoUploadTools event={event} user={user} />
            </div>}
            <div>
              
              {/* <p>Registration Method: {event.registrationMethod} </p> */}
              {['superadmin', 'admin', 'operation_admin', 'accounting', 'photographer'].includes(user.role) && <Button style={{ background: '#BD188B', color: 'white'}} onClick={handleEndRegistration} icon={<CloseOutlined />} >{intl.formatMessage({ id: `app.endRegPhotographer`})}</Button>}
              <Button style={{ background: '#BD188B', color: 'white'}} icon={<FileTextOutlined />}>
                <CSVLink style={{color: 'white'}} data={uploadTable} filename={event._id + '-photographer-list.csv'}>
                  Export as csv
                </CSVLink>
              </Button>
              {event.registrationMethod === 'manual' && (
                <Form form={form} layout='inline' onFinish={handleSubmit}>
                  <div style={{display: 'flex', paddingTop: 10, width: '100%'}}>
                    <div style={{width: '100%', paddingLeft: 5}}><Form.Item
                      name={['photographerId']}
                      // label={<FormattedMessage id='app.addPhotographer' defaultMessage='Add Photographer' />}
                    >
                      <AdminUserSelect role='photographer' />
                    </Form.Item></div>
                    <Form.Item>
                      <Button style={{ background: '#BD188B', color: 'white'}} icon={<PlusOutlined />} htmlType='submit'>
                        {/* <FormattedMessage id='app.add' defaultMessage='Add' /> */}
                        <FormattedMessage id='app.addPhotographer' defaultMessage='Add Photographer' />
                      </Button>
                    </Form.Item>
                  </div>   
                </Form>
              )} 
              {/* <Button type='primary' onClick={handleEndRegistration} disabled={!event.registrable} danger>
                <FormattedMessage id='app.endRegistration' defaultMessage='End the registration' />
              </Button> */}
              {table}
            </div>
          </div>
          ) 
          : event?.userUpload?.approved === true && <div style={{
            justifyContent: 'center',
            background: "white",
            border: "1px solid LightGrey",
            borderRadius: "25px",
            padding: "15px",
            margin: "10px 15px"
            }}><PhotoUploadTools event={event} user={user} />
          </div>
          }
      {/* {(event.createdByUserId === user._id && user.role === 'photographer' && event.registrationMethod === 'manual' ) && (
        <div style={{ 
          justifyContent: 'center',
          background: "white",
          border: "1px solid LightGrey",
          borderRadius: "25px",
          padding: "15px",
          margin: "10px 15px"
          }}>
          <div>
            {(['superadmin', 'admin', 'operation_admin', 'accounting' ].includes(user.role) && event.registrationMethod !== 'manual') && <Button style={{ background: '#BD188B', color: 'white'}} onClick={handleEndRegistration} icon={<CloseOutlined />} >{intl.formatMessage({ id: `app.endRegPhotographer`})}</Button>}
            <Button style={{ background: '#BD188B', color: 'white'}} icon={<FileTextOutlined />}>
              <CSVLink style={{color: 'white'}} data={uploadTable} filename={event._id + '-photographer-list.csv'}>
                Export as csv
              </CSVLink>
            </Button>
            {event.registrationMethod === 'manual' && (
              <Form form={form} layout='inline' onFinish={handleSubmit} >
                <div style={{display: 'flex', paddingTop: 10, width: '100%'}}>
                  <div style={{width: '100%', paddingLeft: 5}}><Form.Item
                    name={['photographerId']}
                    // label={<FormattedMessage id='app.addPhotographer' defaultMessage='Add Photographer' />}
                  >
                    <AdminUserSelect role='photographer' />
                  </Form.Item></div>
                  <Form.Item>
                    <Button style={{ background: '#BD188B', color: 'white'}} icon={<PlusOutlined />} htmlType='submit'>
                      <FormattedMessage id='app.addPhotographer' defaultMessage='Add Photographer' />
                    </Button>
                  </Form.Item>
                </div>   
              </Form>
            )} 
            {table}
          </div>
        </div>)} */}
    </MaintenanceMessage>
  )
})

export default PhotoUpload
